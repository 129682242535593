<template>
  <div class="about">
    <div class="f1">
      <div class="title">
        <p data-aos="fade-up" data-aos-duration="500">展望未来</p>
        <span data-aos="fade-up" data-aos-duration="500">融合行业经验，在赢端持续发展在科技、市场、运营三个方面的核心优势</span>
      </div>
        <ul>
          <li data-aos="fade-right" data-aos-duration="500">
            <div class="imgWrap">
              <img src="../../assets/About/f1-1.png" alt="" />
            </div>
            <div class="text">
              <p>科技</p>
              <span>科技赋能、数据助力<br/>打造闭环、精准适配</span>
            </div>
          </li>
          <li data-aos="fade-up" data-aos-duration="500">
            <div class="imgWrap">
              <img src="../../assets/About/f1-2.png" alt="" />
            </div>
            <div class="text">
              <p>市场</p>
              <span>重点场景、整合营销<br/>用户触达、行业洞察</span>
            </div>
          </li>
          <li data-aos="fade-left" data-aos-duration="500">
            <div class="imgWrap">
              <img src="../../assets/About/f1-3.png" alt="" />
            </div>
            <div class="text">
              <p>运营</p>
              <span>千人千面、业务建模<br/>联合经营、相互触动</span>
            </div>
          </li>
        </ul>
    </div>

    <div class="f2">
      <div class="title">
        <p data-aos="fade-up" data-aos-duration="500" >企业文化</p>
        <span data-aos="fade-up" data-aos-duration="500">企业赢文化：赢=亡+口+月+贝+凡<br/>亡：危机意识 &nbsp;  口：口口相传 &nbsp;  月：时间观念 &nbsp;  贝：取财有道 &nbsp;  凡：平凡心态</span>
      </div>
        <ul>
          <li data-aos="fade-right" data-aos-duration="500">
            <div class="imgWrap">
              <img src="../../assets/About/f2-01.png" alt="" />
            </div>
            <div class="text">
              <p>企业使命</p>
              <span>让客户关系更有价值</span>
            </div>
          </li>
          <li data-aos="fade-up" data-aos-duration="500">
            <div class="imgWrap">
              <img src="../../assets/About/f2-02.png" alt="" />
            </div>
            <div class="text">
              <p>企业愿景</p>
              <span>循环增长 合作共赢</span>
            </div>
          </li>
          <li data-aos="fade-left" data-aos-duration="500">
            <div class="imgWrap">
              <img src="../../assets/About/f2-03.png" alt="" />
            </div>
            <div class="text">
              <p>企业价值观</p>
              <span>守正出奇 说到做到</span>
            </div>
          </li>
        </ul>
    </div>

    <div class="f3">
      <div class="title"  >品牌故事</div>
      <ul style="height:1290px">
        <div class="arrow"><img src="../../assets/About/arrow.png" alt=""></div>
        <li data-aos="fade-left" >
          <div class="year"><span>2020</span>年</div>
          <div class="info">重磅升级权益SaaS平台，实现用户全生命周期一站式经营</div>
        </li>
        <li data-aos="fade-right">
          <div class="info">日交易额超1亿</div>
          <div class="year"><span>2019</span>年</div>
        </li>
        <li data-aos="fade-left" >
          <div class="year"><span>2018</span>年</div>
          <div class="info">首次提出场景营销服务的概念，为金融企业提供更精准的流量与用户转化</div>
        </li>
        <li data-aos="fade-right" >
          <div class="info">成为京东开普勒项目金融行业首家服务商</div>
          <div class="year"><span>2017</span>年</div>
        </li>
        <li data-aos="fade-left" >
          <div class="year"><span>2016</span>年</div>
          <div class="info">游戏化营销平台与权益商品供应服务突破100家客户</div>
        </li>
        <li data-aos="fade-right" >
          <div class="info">为银行在天猫开设首家旗舰店，开启互联网流量经营新模式</div>
          <div class="year"><span>2014</span>年</div>
        </li>
        <li data-aos="fade-left" >
          <div class="year"><span>2013</span>年</div>
          <div class="info">快礼物虚拟权益供应平台上线</div>
        </li>
        <li data-aos="fade-right" >
          <div class="info">公司成立专注于金融行业营销服务及权益供应</div>
          <div class="year"><span>2011</span>年</div>
        </li>
      </ul>
    </div>

    <div class="f4">
      <div  class="title" data-aos="fade-up" data-aos-duration="500"  >荣誉资质</div>
      <div class="box">
        <div class="boxLeft" data-aos="fade-right" data-aos-duration="500">
          <div class="subTitle">企业信用证书</div>
          <ul>
            <li>
              <img src="../../assets/About/f3-1.png" alt="">
              <p>诚信经营示范等级证书</p>
            </li>
            <li>
              <img src="../../assets/About/f3-2.png" alt="">
              <p>中国诚信企业家</p>
            </li>
          </ul>
        </div>

        <div class="boxRight"  data-aos="fade-left" data-aos-duration="500">
          <div class="subTitle">守合同重信用证书</div>
          <ul>
            <li>
              <img src="../../assets/About/f3-3.png" alt="">
              <p>上海市守合同重信用企业</p>
            </li>
            <li>
              <img src="../../assets/About/f3-4.png" alt="">
              <p>合同信用3A等级认定证书</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

   <div class="f5">
      <div class="title">质量体系证书</div>
      <ul >
        <li data-aos="slide-right" >
          <img src="../../assets/About/f4-1.png" alt="">
          <p>ISO9001质量管理体系认证</p>
        </li>
        <li data-aos="slide-up" >
          <img src="../../assets/About/f4-2.png" alt="">
          <p>ISO20000管理体系认证</p>
        </li>
        <li data-aos="slide-left">
          <img src="../../assets/About/f4-3.png" alt="">
          <p>ISO20071管理体系认证</p>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        // key: value
      }
    },
    mounted () {
    },
  }
</script>

<style src="./About.scss" lang="scss" scoped></style>